import $ from 'jquery';
import gsap from 'gsap';

export default class TiltImage {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
  }

  init() {
    if (this.$element.length) {
      this.tilt();
    }

    return false;
  }

  tilt() {
    this.$element.mousemove((e) => {
      const winX = $(window).width();
      const winY = $(window).height();
      const $that = $(e.target);
      const x = e.pageX - $that.offset().left;
      const y = e.pageY - $that.offset().top;
      const newX = x - winX / 2;
      const newY = y - winY / 2;

      let speed = '0.02';

      $that.each(() => {
        speed *= -1;
        gsap.to($that, 1, { x: (1 - newX * speed), y: (1 - newY * speed) });
      });
    });

    this.$element.mouseout(() => {
      gsap.to(this.$element, 1, { x: 0, y: 0 });
      gsap.to(this.$element.children(), 1, { x: 0, y: 0 });
    });
  }
}
