import $ from 'jquery';
import gsap from 'gsap';

export default class Tab {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$line = $(`${this.element}_line`);
    this.$item = this.$element.find('> li a');
    this.$wrapper = $(`${this.element}_wrapper`);
    this.$panel = $(`${this.element}_elm`);
  }

  init() {
    if (this.$element.length) {
      this.tabs();
    }

    return false;
  }

  tabs() {
    this.$element.off('click');

    this.$element.on('click', 'a', (event) => {
      event.preventDefault();

      const $that = $(event.target);
      const panelsId = $that.attr('href');
      const offset = $that.offset();
      const offsetWrap = this.$wrapper.offset();
      const duration = 0.5;
      const isHome = $that.parents('.el_tabs_wrapper').attr('data-page');

      // Reset
      this.$item.removeClass('active');
      this.$panel.removeClass('active');

      if (isHome === 'home') {
        gsap.to(this.$item, duration, { opacity: 0.4 });
        gsap.to($('.el_products_desc'), duration, { opacity: 1 });
        gsap.to(this.$panel, duration, { opacity: 0, display: 'none' });
      } else {
        gsap.to(this.$panel, duration, { opacity: 0 });
      }

      if (isHome === 'home') {
        // Tabs slide line
        gsap.to(this.$line, duration, {
          css: {
            top: (offset.top - offsetWrap.top),
          },
          ease: 'back.inOut',
        });
      }

      // Tabs menu
      $that.toggleClass('active');

      if (isHome === 'home') {
        gsap.to($that, { opacity: 1 });
      }

      // Tabs panel
      $(panelsId).toggleClass('active');

      if (isHome === 'home') {
        const $panelDesc = $(panelsId).find('.el_products_desc');
        gsap.from(panelsId, duration, { opacity: 1, display: 'block' });
        gsap.from($panelDesc, duration, { opacity: 0, right: 0 });
      } else {
        if ($(panelsId).hasClass('active')) {
          const heightActive = $(panelsId).outerHeight();
          gsap.to($(panelsId).parent(), duration, { height: heightActive });
        }

        gsap.from(panelsId, duration, { opacity: 1 });
      }
    });

    this.$panel.first().trigger('click');
  }
}
