import $ from 'jquery';
import gsap from 'gsap';
import initFunctions from '../../init';

gsap.config({
  nullTargetWarn: false,
});

function headerAnimation(container) {
  const headerContainer = container.parentNode.parentNode.querySelector('header');
  const fadeLogo = document.getElementById('el_logo');
  const fadeMenu = document.getElementById('el_nav');
  const fadeItems = headerContainer.querySelectorAll('.el_animated-fadeBottom');
  const fadeBtn = headerContainer.querySelector('.el_animated-fadeLeft');

  let styleLogo = '';
  let styleMenu = '';
  let tl;

  if (fadeLogo) {
    styleLogo = window.getComputedStyle(fadeLogo);
  }

  if (fadeMenu) {
    styleMenu = window.getComputedStyle(fadeMenu);
  }

  if (fadeLogo && fadeItems) {
    if (styleLogo && styleLogo.opacity === '0' && styleMenu && styleMenu.opacity === '0') {
      // After Loader
      tl = gsap
        .timeline({ delay: 0.9 })
        .add('start')
        .to(
          fadeLogo,
          {
            duration: 0.4,
            opacity: 1,
          },
          'start',
        )
        .to(fadeItems, {
          duration: 0.4,
          'margin-top': 0,
          opacity: 1,
          stagger: 0.1,
        });

      if (fadeBtn) {
        tl.to(fadeBtn, {
          duration: 0.5,
          translateX: 0,
          opacity: 1,
        });
      }
    }
  }

  return tl;
}

function contentAnimation(container) {
  const tl = gsap.timeline();
  const fadeTopHead = container.querySelectorAll('.el_animated-fadeTopHead');
  let title = '';
  let titleWrap = '';
  let widthBar = '';
  let styleBar = '';

  if (container.getAttribute('data-barba-namespace') === 'home') {
    titleWrap = document.querySelector('.el_animated-titleHome');
    title = document.querySelector('.el_animated-titleHome .el_animated-fade');
    widthBar = document.querySelector('.el_animated-titleHome .el_animated-width');

    if (widthBar) {
      styleBar = window.getComputedStyle(widthBar);
    }
  }

  if (fadeTopHead) {
    if (container.getAttribute('data-barba-namespace') === 'home' && styleBar !== '' && title && titleWrap) {
      tl.add('start')
        .set(title, { opacity: 0 })
        .to(
          [titleWrap, fadeTopHead],
          {
            duration: 0.8,
            translateY: -10,
            opacity: 1,
            stagger: 0.7,
          },
          'start',
        )
        .to(
          widthBar,
          {
            duration: 0.5,
            width: `calc(100% - ${styleBar.left}`,
          },
          'start+=0.6',
        )
        .to(
          title,
          {
            opacity: 1,
          },
          'start+=1.1',
        )
        .to(
          widthBar,
          {
            duration: 0.8,
            width: styleBar.width,
          },
          'start+=1.2',
        );
    } else {
      tl.to(fadeTopHead, {
        duration: 0.8,
        translateY: -10,
        opacity: 1,
        stagger: 0.2,
      });
    }
  }

  return tl;
}

export default class Revealer {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.layer = `${this.element}_layer`;
  }

  init() {
    if (this.$element.length) {
      const options = {
        debug: true,
        transitions: [
          {
            name: 'page-transition',
            sync: true,
            leave: (data) => {
              const parser = new DOMParser();
              const domCurrent = parser.parseFromString(
                data.current.html,
                'text/html',
              );
              const domNext = parser.parseFromString(
                data.next.html,
                'text/html',
              );

              const currentLang = domCurrent.querySelector('[lang]').lang;
              const nextLang = domNext.querySelector('[lang]').lang;

              if (currentLang !== nextLang) {
                window.location.reload();
              } else {
                this.pageTransitionIn();
              }
            },
            enter: (data) => {
              this.pageTransitionOut(data.next.container);
              window.scrollTo(0, 0);
            },
            once: (data) => {
              setTimeout(() => {
                headerAnimation(data.next.container);
                setTimeout(() => {
                  contentAnimation(data.next.container);
                }, 1500);
              }, 4800);
            },
            after: (data) => {
              if (data.next.container.className === 'el_page-template-page-contact') {
                // Init contactForm
                const $wpcf7 = data.next.container.querySelector('.wpcf7');

                if (typeof $wpcf7 !== 'undefined') {
                  /* eslint-disable */
                  location.reload();
                  /* eslint-enable */
                  // const $form = $('.wpcf7-form').eq(0);

                  // /* eslint-disable */
                  //   $wpcf7.initForm($form);
                  //   $wpcf7cf.initForm($form);
                  // /* eslint-enable */

                  // // load CPF Scripts (Country & phone fields)
                  // const scriptsCpf = [];
                  // const $scriptsCpf = $('script');

                  // $.each($scriptsCpf, (i, elm) => {
                  //   const elmSrc = elm.getAttribute('src');
                  //   const elmClass = elm.getAttribute('class');

                  //   if (elmSrc === null && elmClass === null) {
                  //     scriptsCpf[i] = elm.innerHTML;
                  //     elm.remove();
                  //   }
                  // });

                  // scriptsCpf.forEach((elm) => {
                  //   const scriptCpfRefresh = document.createElement('script');
                  //   scriptCpfRefresh.innerHTML = elm;
                  //   data.next.container.parentElement.parentElement
                  // .appendChild(scriptCpfRefresh);
                  // });
                }
              }

              initFunctions();
            },
            afterLeave: (data) => {
              // Set <body> classes for "next" page
              const nextHtml = data.next.html;
              const response = nextHtml.replace(
                /(<\/?)body( .+?)?>/gi,
                '$1notbody$2>',
                nextHtml,
              );
              const bodyClasses = $(response).filter('notbody').attr('class');
              $('body').attr('class', bodyClasses);
            },
          },
        ],
      };
      return options;
    }

    return false;
  }

  pageTransitionIn() {
    return gsap
      .timeline()
      .set(this.layer, {
        opacity: 1,
        bottom: 0,
        height: 0,
      })
      .to(
        this.layer,
        {
          duration: 1,
          height: '100vh',
          stagger: 0.35,
        },
        0.2,
      );
  }

  pageTransitionOut(nextContainer) {
    return gsap
      .timeline({ delay: 0.5 })
      .from(nextContainer, { opacity: 0 })
      .add('start')
      .to(
        this.layer,
        {
          duration: 1,
          height: '0',
          top: '0',
          bottom: 'initial',
          stagger: 0.5,
        },
        'start',
      )
      .set(this.layer, {
        opacity: 0,
        bottom: '0',
        top: 'initial',
      })
      .call(headerAnimation, [nextContainer], 'start')
      .call(contentAnimation, [nextContainer], 'start+=0.8');
  }
}
