import $ from 'jquery';
import gsap from 'gsap';

export default class Gallery {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$slide = this.$element.find('.el_slide_item').children();
  }

  init() {
    if (this.$element.length) {
      $('body').removeClass('el_modal-isOpen');
      this.modal();
    }

    return false;
  }

  createModal() {
    const dynamicModal = '<div id="el_modal_id" class="el_modal">'
      + '  <div class="el_modal_content">'
      + '    <div class="el_modal_close el_circle">'
      + '       <span class="el_circle_line"></span>'
      + '       <span class="el_circle_line"></span>'
      + '    </div>'
      + '    <div class="el_modal_slides"></div>'
      + '    <a class="el_modal_prev">'
      + '       <i class="el_icon-arrowRight-w el_circle"></i>'
      + '    </a>'
      + '    <a class="el_modal_next">'
      + '       <i class="el_icon-arrowRight-w el_circle"></i>'
      + '  </div>'
      + '</div>';

    this.$element.after(dynamicModal);

    this.$slide.each((i, element) => {
      const $slide = $(element);
      const altSlide = $slide.find('img').attr('alt');
      const imgSlide = $slide.attr('data-modal');
      const itemSlide = `<div class="el_modal_item"><img src="${imgSlide}" alt="${altSlide}" loading="lazy"></div>`;

      $(itemSlide).appendTo('.el_modal_slides');
    });
  }

  modal() {
    let slideIndex = 1;
    this.createModal();

    const $modal = $('#el_modal_id');
    const $prev = $modal.find('.el_modal_prev');
    const $next = $modal.find('.el_modal_next');
    const $close = $modal.find('.el_modal_close');

    this.$slide.off('click');
    $close.off('click');
    $prev.off('click');
    $next.off('click');

    function showSlide(n) {
      const slides = document.getElementsByClassName('el_modal_item');

      if (n > slides.length) {
        slideIndex = 1;
      }

      if (n < 1) {
        slideIndex = slides.length;
      }

      for (let i = 0; i < slides.length; i += 1) {
        slides[i].style.display = 'none';
      }

      slides[slideIndex - 1].style.display = 'block';
    }

    function changeSlide(n) {
      showSlide(slideIndex += n);
    }

    this.$slide.on('click', (e) => {
      const currentSlide = $(e.target).attr('data-index');
      $('body').addClass('el_modal-isOpen');
      showSlide(slideIndex = currentSlide);
      $modal.fadeIn();
    });

    $close.on('click', () => {
      $('body').removeClass('el_modal-isOpen');
      gsap.to($prev, { opacity: 0 });
      slideIndex = 1;
      $modal.fadeOut();
    });

    $prev.on('click', () => {
      changeSlide(-1);
    });

    $next.on('click', () => {
      gsap.to($prev, { opacity: 1 });
      changeSlide(1);
    });
  }
}
