import $ from 'jquery';
import 'slick-slider/slick/slick';

export default class SlidePartners {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$slides = this.$element.find('.el_partners_elm');
  }

  init() {
    if (this.$element.length && this.$slides.length) {
      this.slider();

      // On resize
      $(window).on('load resize', () => {
        this.slider();
      });
    }

    return false;
  }

  slider() {
    const $slide = $(this.$element);
    const initSlide = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 8,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };

    $slide.not('.slick-initialized').slick(initSlide);
  }
}
