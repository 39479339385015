import $ from 'jquery';

export default class Map {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$allMaps = $('.el_pad_map');
  }

  init() {
    if (this.$element.length) {
      /* eslint-disable */
      $.each(this.$allMaps, (i, elm) => {
        const mapID = elm.id;
        const $mapID = $(`#${mapID}`);
        const lat = $mapID.attr('data-lat');
        const lng = $mapID.attr('data-lng');
        const addressHtml = $mapID.next().find('.el_pad_legend').html();
        const addressText = $mapID.next().find('.el_pad_legend').text();
        const address = addressText.replace(/\r?\n/g, '+').replace(/\s+/g, '+');
        const routeLink = `https://www.google.com/maps/search/${address}/@${lat},${lng},17z/`;
        const route = `<a href="${routeLink}" target="_blank" class="el_btn el_btn-default" data-text="Let's go !"><span>Let's go !</span></a>`;
        const myMap = L.map(mapID).setView([lat, lng], 14);

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}@2x.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        }).addTo(myMap);

        const mapIcon = L.icon({
          iconUrl: '../wp-content/themes/eliberty-corporate/images/svg/el-pointer.svg',
          iconSize: [45, 71],
        });

        L.marker([lat, lng], {
          icon: mapIcon,
        }).addTo(myMap).bindPopup(addressHtml + route);
      });
      /* eslint-enable */
    }

    return false;
  }
}
