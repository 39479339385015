import $ from 'jquery';
import gsap from 'gsap';

export default class HeaderFix {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$headerLogo = this.$element.find('.el_header_logo');
  }

  init() {
    if (this.$element.length) {
      // On scroll
      this.scroll();

      // On resize
      $(window).on('resize', () => this.scroll());
    }

    return false;
  }

  scroll() {
    $(window).off('scroll');
    $(window).on('scroll', () => {
      const position = $(window).scrollTop();
      if (position > 100) {
        this.animationStart();
      } else {
        this.resize();
      }
    });
  }

  resize() {
    if ('matchMedia' in window) {
      if (window.matchMedia('(max-width:500px)').matches) {
        this.animationEnd(112, 40);
      } else if (window.matchMedia('(max-width:999px)').matches) {
        this.animationEnd(156, 56);
      } else {
        this.animationEnd(156, 56);
      }
    }
  }

  animationStart() {
    gsap.to(this.element, { backgroundColor: 'rgba(0, 45, 75, 1)', delay: 0.5 });
    gsap.to(this.$headerLogo, { width: 112, height: 40, delay: 0.3 });
  }

  animationEnd(sizeW, sizeH) {
    gsap.to(this.element, { backgroundColor: 'rgba(0, 45, 75, 0)', delay: 0.5 });
    gsap.to(this.$headerLogo, { width: sizeW, height: sizeH, delay: 0.3 });
  }
}
