import 'es6-promise';
import barba from '@barba/core';
import Revealer from './components/animations/Revealer';
import initFunctions from './init';

// Page transition
const revealer = new Revealer('.el_revealer');
barba.init(revealer.init());

// Global elements
initFunctions();
