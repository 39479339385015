import $ from 'jquery';
import { gsap, ScrollTrigger } from 'gsap/all';

export default class Scroll {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
  }

  init() {
    if (this.$element.length) {
      this.scrollTrigger();
    }

    return false;
  }

  scrollTrigger() {
    const content = document.querySelector(this.element);
    const fadeTitles = content.querySelectorAll('.el_titles');
    const animLeft = content.querySelectorAll('.el_animated-left');
    const fadeLeft = content.querySelectorAll('.el_animated-fadeLeft');
    const fadeRight = content.querySelectorAll('.el_animated-fadeRight');
    const fadeTop = content.querySelectorAll('.el_animated-fadeTop');

    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals('ScrollTrigger', ScrollTrigger);
      gsap.defaults({ ease: 'power3' });

      gsap.utils.toArray(fadeTitles).forEach((title) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: title,
              start: 'center bottom',
              end: 'top top',
              scrub: 1,
              // markers: true,
            },
          })
          .to(title, {
            duration: 0.3,
            translateX: 0,
            opacity: 1,
          });
      });

      gsap.utils.toArray(fadeLeft).forEach((blockLeft) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: blockLeft,
              start: 'center bottom',
              end: 'top top',
              scrub: 1,
            },
          })
          .to(blockLeft, {
            duration: 0.05,
            translateX: 0,
            opacity: 1,
          });
      });

      gsap.utils.toArray(animLeft).forEach((blockLeft) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: blockLeft,
              start: 'center bottom',
              end: 'top top',
              scrub: 1,
            },
          })
          .to(blockLeft, {
            duration: 0.05,
            translateX: 0,
          });
      });

      gsap.utils.toArray(fadeRight).forEach((blockRight) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: blockRight,
              start: 'center bottom',
              end: 'top center',
              scrub: 1,
            },
          })
          .to(blockRight, {
            duration: 0.05,
            translateX: 0,
            opacity: 1,
          });
      });

      gsap.utils.toArray(fadeTop).forEach((blockTop) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: blockTop,
              start: 'center bottom',
              end: 'top top',
              scrub: 1,
            },
          })
          .to(blockTop, {
            duration: 0.8,
            translateY: -10,
            opacity: 1,
          });
      });
    }
  }
}
