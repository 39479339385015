import $ from 'jquery';
import gsap from 'gsap';

export default class Slide {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$slides = this.$element.find('.el_slide_item');
    this.$prev = this.$element.find('[data-pagination="prev"]');
    this.$next = this.$element.find('[data-pagination="next"]');
  }

  init() {
    if (this.$element.length && this.$slides.length) {
      this.slider();
    }

    return false;
  }

  slider() {
    let oldSlide = 0;
    let activeSlide = 0;
    const duration = 0.5;
    const delay = 3;

    function animateSlide(e, elm) {
      const $that = $(e.target).parents('[data-pagination]');
      const $slides = $(elm).find('.el_slide_item');
      const $prev = $(elm).find('[data-pagination="prev"]');
      const $next = $(elm).find('[data-pagination="next"]');
      const firstImg = `${elm}_first`;
      const secondImg = `${elm}_second`;
      const thirdImg = `${elm}_third`;
      const $currentCount = $(elm).find('.el_slide_current');
      const nbItem = $(elm).find('.el_slide_pagination_count').attr('data-number');

      oldSlide = activeSlide;
      if (gsap.isTweening($(elm))) {
        return;
      }

      if ($that.attr('data-pagination') === 'prev' || $that.attr('data-pagination') === 'next') {
        activeSlide = ($that.attr('data-pagination') === 'next') ? (activeSlide += 1) : (activeSlide -= 1);
      }

      activeSlide = activeSlide < 0 ? 0 : activeSlide;
      activeSlide = activeSlide > $slides.length - 1 ? $slides.length - 1 : activeSlide;

      if (oldSlide === activeSlide) {
        return;
      }

      const tlSlide = gsap.timeline();
      const $dataSlide = $(`[data-slide="${activeSlide}"`);
      const $dataSlidePrev = $(`[data-slide="${activeSlide - 1}"`);
      const $dataSlideNext = $(`[data-slide="${activeSlide + 1}"`);
      const slideCount = (activeSlide > 0) ? (activeSlide + 1) * nbItem : nbItem;

      $slides.removeClass('active');
      $dataSlide.addClass('active');
      $currentCount.text(slideCount);

      if (activeSlide >= 1) {
        gsap.to($prev, duration, { opacity: 1 });
      } else {
        gsap.to($prev, duration, { opacity: 0 });
      }

      if (activeSlide < $slides.length - 1) {
        gsap.to($next, duration, { opacity: 1 });
      } else {
        gsap.to($next, duration, { opacity: 0 });
      }

      if ($that.attr('data-pagination') === 'next') {
        tlSlide
          .to($dataSlidePrev.find(firstImg), 0.3, { autoAlpha: 0 })
          .to($dataSlidePrev.find(secondImg), 0.3, { autoAlpha: 0 }, '+=0.01')
          .to($dataSlidePrev.find(thirdImg), 0.3, { autoAlpha: 0 }, '+=0.01')
          .to($dataSlide.find(firstImg), 0.3, { autoAlpha: 1 })
          .to($dataSlide.find(secondImg), 0.3, { autoAlpha: 1 }, '+=0.01')
          .to($dataSlide.find(thirdImg), 0.3, { autoAlpha: 1 }, '+=0.01');
      } else {
        tlSlide
          .to($dataSlideNext.find(firstImg), 0.3, { autoAlpha: 0 })
          .to($dataSlideNext.find(secondImg), 0.3, { autoAlpha: 0 }, '+=0.01')
          .to($dataSlideNext.find(thirdImg), 0.3, { autoAlpha: 0 }, '+=0.01')
          .to($dataSlide.find(firstImg), 0.3, { autoAlpha: 1 })
          .to($dataSlide.find(secondImg), 0.3, { autoAlpha: 1 }, '+=0.01')
          .to($dataSlide.find(thirdImg), 0.3, { autoAlpha: 1 }, '+=0.01');
      }

      gsap.delayedCall(delay, tlSlide.play());
    }

    this.$prev.off('click');
    this.$next.off('click');

    this.$next.on('click', (e) => {
      animateSlide(e, this.element);
    });

    this.$prev.on('click', (e) => {
      animateSlide(e, this.element);
    });
  }
}
