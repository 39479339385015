import gsap from 'gsap';

import HeaderFix from './components/scroll/HeaderFix';
import Scroll from './components/scroll/Scroll';
import SmoothScroll from './components/scroll/Smooth';
import Timeline from './components/scroll/Timeline';

import Menu from './components/global/Menu';
import ImageToSvg from './components/global/ImageToSvg';
import TiltImage from './components/global/TiltImage';
import Slide from './components/global/Slide';
import SlideNews from './components/global/SlideNews';
import SlideResults from './components/global/SlideResults';
import SlidePartners from './components/global/SlidePartners';
import Tab from './components/global/Tab';
import Gallery from './components/global/Gallery';
import Accordion from './components/global/Accordion';
import Contact from './components/global/Contact';
import Map from './components/global/Map';

export default function initFunctions() {
  // Global elements *************************************
  gsap.config({ nullTargetWarn: false });

  // Scroll
  const scroll = new Scroll('.el_subcontent');
  scroll.init();

  // ImageToSvg
  const imgToSvg = new ImageToSvg('img[src$=".svg"]');
  imgToSvg.init();

  // TiltImage
  const tilt = new TiltImage('[data-tilt="true"]');
  tilt.init();

  // HeaderFix
  const headerFix = new HeaderFix('.el_header');
  headerFix.init();

  // Menu
  const menu = new Menu('.el_menu');
  menu.init();

  // Smooth Scroll
  const smooth = new SmoothScroll('a[href^="#"]');
  smooth.init();

  // Slide
  const slide = new Slide('.el_slide');
  slide.init();

  // Slide News
  const slideNews = new SlideNews('.el_pn_slide');
  slideNews.init();

  // Slide Results (Post)
  const slideResults = new SlideResults('.el_pr_list');
  slideResults.init();

  // Slide Partners
  const slidePartners = new SlidePartners('.el_partners_list');
  slidePartners.init();

  // Tab (Products & Contact)
  const tab = new Tab('.el_tabs');
  tab.init();

  // Gallery (Home & About)
  const gallery = new Gallery('.el_slide[data-gallery="true"]');
  gallery.init();

  // Accordion (About)
  const accordion = new Accordion('.el_pti_item');
  accordion.init();

  // Timeline (About)
  const timeline = new Timeline('.el_pti');
  timeline.init();

  // Contact Form (Contact & Jobs)
  const contactForm = new Contact('.wpcf7');
  contactForm.init();

  // Map
  const map = new Map('.el_pad_map');
  map.init();
}
