import $ from 'jquery';

export default class SmoothScroll {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.tab = 'el_tabs_menu';
  }

  init() {
    if (this.$element.length) {
      this.smooth();
    }

    return false;
  }

  smooth() {
    this.$element.on('click', (event) => {
      const $that = $(event.target.hash);

      if ($that && $(event.target).hasClass(this.tab) === false) {
        event.preventDefault();
        const thatTop = $that.offset().top;

        $('html, body').animate({
          scrollTop: thatTop - 130,
        }, 1200);
      }
    });
  }
}
