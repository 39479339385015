import $ from 'jquery';

function customInput(e) {
  const elm = e;
  const fileInput = elm.querySelector('[type="file"]');
  const label = elm.querySelector('[data-js-label]');

  fileInput.onchange = () => {
    if (!fileInput.value) {
      return;
    }

    const value = fileInput.value.replace(/^.*[\\]/, '');
    elm.className += ' chosen';
    label.innerText = value;
  };
}

export default class Contact {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$input = this.$element.find('.el_wpcf7-input');
    this.inputsFile = document.querySelectorAll('.el_field-file');
  }

  init() {
    if (this.$element.length) {
      this.contactForm();
    }

    return false;
  }

  contactForm() {
    this.$input.focus((e) => {
      const $inputParent = $(e.target).parent();
      $inputParent.siblings('label').addClass('active');
    }).blur((e) => {
      const $inputParent = $(e.target).parent();
      const inputVal = $(e.target).val();

      if (inputVal === '') {
        $inputParent.siblings('label').removeClass('active');
      }
    });

    if (this.inputsFile.length) {
      for (let i = 0; i < this.inputsFile.length; i += 1) {
        customInput(this.inputsFile[i]);
      }
    }
  }
}
