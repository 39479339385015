import $ from 'jquery';
import { gsap, ScrollTrigger } from 'gsap/all';

export default class Timeline {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.wrapper = `${this.element}_inner`;
    this.item = `${this.element}_item`;
    this.infos = `${this.element}_infos`;
    this.date = `${this.element}_date`;
    this.title = `${this.element}_title`;
    this.details = `${this.element}_details`;
    this.bar = `${this.element}_time`;
    this.dot = `${this.element}_dot`;
    this.timeline = 'el_timeline';
  }

  init() {
    if (this.$element.length) {
      this.scroll();
    }

    return false;
  }

  scroll() {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals('ScrollTrigger', ScrollTrigger);
      gsap.defaults({ ease: 'power3' });

      gsap.utils.toArray(this.item).forEach((section) => {
        const $details = $(section).find(this.details);
        const $dot = $(section).find(this.dot);
        const $date = $(section).find(this.date);
        const $title = $(section).find(this.title);

        gsap
          .timeline({
            scrollTrigger: {
              trigger: section,
              start: 'top center',
              end: 'bottom center',
              scrub: 1,
              // markers: true, /* For debug */
            },
          })
          .add('start')
          .from($dot, {
            autoAlpha: 0,
            scale: 0,
          })
          .from([$date, $title], {
            autoAlpha: 0,
            x: '-50%',
            skewX: 10,
            duration: 0.4,
          }, 'start')
          .from($details, {
            autoAlpha: 0,
            y: '30%',
            duration: 0.8,
          }, 'start');
      });
    }
  }
}
