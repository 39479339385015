import $ from 'jquery';
import 'slick-slider/slick/slick';

export default class SlideNews {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$slide = this.$element.find('.el_pn_inside');
    this.$slides = this.$element.find('.el_pn_post');
  }

  init() {
    if (this.$element.length && this.$slides.length) {
      this.slider();

      // On resize
      $(window).on('load resize', () => {
        this.slider();
      });
    }

    return false;
  }

  slider() {
    const $slide = $(this.$slide);
    const initSlide = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    if ($(window).width() < 768) {
      $slide.not('.slick-initialized').slick(initSlide);
    } else if ($slide.hasClass('slick-initialized')) {
      $slide.slick('unslick');
    }
  }
}
