import $ from 'jquery';

export default class ImageToSvg {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
  }

  init() {
    if (this.$element.length) {
      this.changeSvg();
    }

    return false;
  }

  changeSvg() {
    this.$element.each((i, element) => {
      const $img = $(element);
      const imgURL = $img.attr('src');
      const attributes = $img.prop('attributes');

      $.get(imgURL, (data) => {
        let $svg = $(data).find('svg');

        $svg = $svg.removeAttr('xmlns:a');
        $.each(attributes, (attribute) => {
          $svg.attr(attribute.name, attribute.value);
        });
        $img.replaceWith($svg);
      }, 'xml');
    });
  }
}
