import $ from 'jquery';
import gsap from 'gsap';

export default class Accordion {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$tab = $(element).find('.el_pti_infos');
    this.$panel = $(element).find('.el_pti_details');
  }

  init() {
    if (this.$element.length) {
      this.accordion();

      // On resize
      $(window).on('resize', () => {
        this.accordion();
      });
    }

    return false;
  }

  accordion() {
    if (this.$panel.length) {
      if ('matchMedia' in window) {
        if (window.matchMedia('(max-width:660px)').matches) {
          gsap.set(this.$panel, { height: 0 });
          this.$element.off('click');

          this.$element.on('click', (e) => {
            const $currentItem = $(e.target).parents('.el_pti_item');

            if ($currentItem.hasClass('active')) {
              gsap.to(this.$panel, { duration: 0.25, height: 0 });
              this.$element.removeClass('active');
            } else {
              if ($currentItem.hasClass('active')) {
                gsap.to(this.$panel, { duration: 0.25, height: 0 });
                this.$element.removeClass('active');
              }
              gsap.to($currentItem.find('.el_pti_details'), { duration: 0.25, height: 'auto' });
              $currentItem.addClass('active');
            }
          });
        } else {
          gsap.to(this.$panel, { duration: 0.25, height: 'auto' });
          this.$element.removeClass('active');
        }
      }
    }
  }
}
