import $ from 'jquery';
import gsap from 'gsap';
import lottie from 'lottie-web';

export default class Menu {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$burger = this.$element.parents().find('.el_burger');
    this.$childMenu = this.$element.children();
    this.subMenu = `${this.element}_sub`;
    this.$subMenu = this.$element.find('.el_menu_sub');
  }

  init() {
    if (this.$element.length) {
      const vw = window.innerWidth || document.documentElement.clientWidth;

      $('body').removeClass('el_menu-isOpen');
      this.$childMenu.removeClass('active');

      this.toggleMenu(vw);
      this.resize();
    }

    return false;
  }

  resize() {
    window.addEventListener('resize', () => {
      const vw = window.innerWidth || document.documentElement.clientWidth;
      this.toggleMenu(vw);
    }, true);
  }

  toggleMenu(vw) {
    if (vw < 769) {
      this.responsiveMenu();
    } else {
      this.desktopMenu();
    }
  }

  desktopMenu() {
    this.$element.parent().removeClass('el_menu_mobile');

    this.$childMenu.off('click');
    this.$burger.off('click');

    this.$childMenu.on('click', (event) => {
      const $that = $(event.target).parent();
      const logo = `${this.subMenu} .el_menu_video`;
      const $logo = $(logo).children();
      const loaderContainer = lottie.loadAnimation({
        container: document.querySelector(logo),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $logo.data('json'),
      });

      // Switch logo & json loader
      const $lastChild = $(logo).find('svg:last-child');
      $logo.replaceWith($lastChild);
      gsap.to($lastChild, { duration: 1, autoAlpha: 1 });

      this.$childMenu.children().removeClass('active');
      loaderContainer.stop();

      if ($that.hasClass('el_menu-hasSubmenu')) {
        $('body').toggleClass('el_menu-isOpen');
        $that.toggleClass('active');

        // Play animation
        loaderContainer.play();
      }
    });

    this.$burger.on('click', () => {
      $('body').removeClass('el_menu-isOpen');
      this.$childMenu.children().removeClass('active');
    });
  }

  responsiveMenu() {
    this.$element.parent().addClass('el_menu_mobile');

    this.$childMenu.off('click');
    this.$burger.off('click');

    this.$childMenu.on('click', (event) => {
      const $that = $(event.target).parent();
      const $submenu = $that.next();
      const submenuHeight = $submenu.find('ul').outerHeight();

      this.$subMenu.css('height', 0);
      this.$childMenu.children().removeClass('active');

      if ($that.hasClass('el_menu-hasSubmenu')) {
        $that.toggleClass('active');

        if ($submenu.outerHeight() === 5) {
          $submenu.css('height', submenuHeight);
        } else {
          $submenu.css('height', 0);
        }
      }
    });

    this.$burger.on('click', () => {
      $('body').toggleClass('el_menu-isOpen');
    });
  }
}
