import $ from 'jquery';
import 'slick-slider/slick/slick';

export default class SlideResults {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.$slide = this.$element.find('.el_pr_list_inside');
    this.$slides = this.$element.find('.el_pr_item');
    this.$next = this.$element.parent().find('.el_pr_pagination');
  }

  init() {
    if (this.$element.length && this.$slides.length) {
      this.slider();

      // On resize
      $(window).on('load resize', () => {
        this.slider();
      });
    }

    return false;
  }

  slider() {
    const $slide = $(this.$slide);
    const initSlide = {
      dots: false,
      arrows: true,
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: '',
      nextArrow: '.el_pr_pagination',
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    };

    $slide.not('.slick-initialized').slick(initSlide);
  }
}
